<template>
  <section class="m-address m-scroll">
    <!-- 头部导航 -->
    <m-header title="我的地址">
      <span slot="right"><router-link to="/zh/address/edit">添加</router-link></span>
    </m-header>
    
    <ul class="list">

      <transition-group name="van-slide-down">
        <li class="item m-padding" v-for="(it,i)  in list" :key="it.id">
          <div class="name-phone">
            <div>
              <img src="@/assets/address/icon_name@2x.png" alt="name">
              <span>{{it.name}}-{{it.surname}}</span>
            </div>
            <div>
              <img src="@/assets/address/icon_tel@2x.png" alt="name">
              <span>{{it.phone}}</span>
            </div>
          </div>
          <div class="addr">
            <img src="@/assets/address/icon_dress@2x.png" alt="">
            <p>{{it.addr}}, {{it.city_arr ? it.city_arr.ename : it.city_name}},{{it.province_arr.ename}},{{it.country_arr.ename}},{{it.code}}</p>
          </div>
          <div class="edit van-hairline--top">
            <div class="defalut" @click="handleDefalut(it)">
              <img v-if="it.audit == 1" src="@/assets/address/icon_xuanzhong@2x.png" alt="defalut">
              <img v-else src="@/assets/address/icon_xuanze@2x.png" alt="defalut">
              <span>默认地址</span>
            </div>
            <div class="btns">
              <div @click="handleEdit(it)"><img src="@/assets/address/icon_bianji@2x.png" alt="edit"><span>编辑</span></div>
              <div @click="handleDelete(it, i)"><img src="@/assets/address/icon_delete@2x.png" alt="edit"><span>删除</span></div>
            </div>
          </div>
        </li>
      </transition-group>
      
    </ul>

    <div class="footer">
      <router-link to="/zh/address/edit">添加新地址</router-link>
    </div>

  </section>
</template>

<script>
import MHeader  from '@/components/zh/m-header.vue'
import { address, address_put, address_del } from '@/api/zh/mine'

export default {
  name:'Address',
  components:{ MHeader },
  data(){
    return {
      list: [],
    }
  },

  created(){
    this.getData()
  },

  methods:{

    // 获取地址列表
    getData() {
      address().then(res => {
        if(res) {
          this.list = res.data
        }
      })
    },

    // 编辑地址
    handleEdit(it) {
      window.sessionStorage.setItem('address', JSON.stringify(it))
      this.$router.push({path: '/zh/address/edit', query: {id:it.id}})
    },

    // 删除地址
    handleDelete(it, i){
      address_del(it.id).then(res => {
        if(res) {
          this.$notify({ type: 'success', duration: 2000, message: '删除成功' })
          this.list.splice(i,1)
        }
      })
      
    },

    //设置默认地址
    handleDefalut(it){
      let params = { audit: 1 }
      address_put(it.id, params).then(res => {
        if(res) {
          this.getData()
          this.$notify({ type: 'success', duration: 2000, message: '设置默认地址成功' })
        }
      })
      
    }, 
  }
}
</script>

<style lang="less" scoped>
@import './list.less';
</style>